import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { User } from '../../models/user.model';
import { LoginResponse } from '../../interfaces/login.response';
import { LoginRequest } from '../../interfaces/login.request';
import { StorageService } from './../storage/storage.service';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService) { }

  login(credentials: LoginRequest): Observable<User> {
    return this.http.get<LoginResponse>(
      `${environment.urlApi}/Login?userName=${credentials.user}&password=${credentials.password}`)
      .pipe(
        map(res => {
          if (res) {
            const userInfo = {
              id: res.Id,
              name: res.Name,
              userName: res.UserName,
              email: res.Email,
              loginSuccess: res.LoginSuccess
            };
            this.storageService.setObject(environment.userInfo, userInfo);
            return userInfo;
          }
        })
      );
  }

  logout() {
    this.storageService.removeItem(environment.userInfo);
  }

}
