import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AutoLoginGuard } from './core/guards/auto-login.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    // canLoad: [AutoLoginGuard]
  },
  {
    path: 'diseases',
    loadChildren: () =>
      import('./pages/diseases/diseases.module').then(
        (m) => m.DiseasesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'crops-diseases',
    loadChildren: () =>
      import('./pages/crops-diseases/crops-diseases.module').then(
        (m) => m.CropsDiseasesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'parcels',
    loadChildren: () =>
      import('./pages/parcels/parcels.module').then((m) => m.ParcelsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pruebas-graficas',
    loadChildren: () =>
      import('./pages/pruebas-graficas/pruebas-graficas.module').then(
        (m) => m.PruebasGraficasPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'incoming-data',
    loadChildren: () =>
      import('./pages/incoming-data/incoming-data.module').then(
        (m) => m.IncomingDataPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'nodes',
    loadChildren: () =>
      import('./pages/nodes/nodes.module').then((m) => m.NodesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sensors',
    loadChildren: () =>
      import('./pages/sensors/sensors.module').then((m) => m.SensorsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'measurements',
    loadChildren: () =>
      import('./pages/measurements/measurements.module').then(
        (m) => m.MeasurementsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'brix-algoholic-degrees',
    loadChildren: () =>
      import('./pages/brix-algoholic-degrees/brix-algoholic-degrees.module').then(
        (m) => m.BrixAlgoholicDegreesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'crops',
    loadChildren: () =>
      import('./pages/crops/crops.module').then(
        (m) => m.CropsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('./pages/alerts/alerts.module').then((m) => m.AlertsPageModule),
  },
  {
    path: 'parcel-detail',
    loadChildren: () =>
      import('./pages/parcel-detail/parcel-detail.module').then(
        (m) => m.ParcelDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'crop-detail',
    loadChildren: () =>
      import('./pages/crop-detail/crop-detail.module').then(
        (m) => m.CropDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'phenology-phase-detail',
    loadChildren: () =>
      import(
        './pages/phenology-phase-detail/phenology-phase-detail.module'
      ).then((m) => m.PhenologyPhaseDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'activity-detail',
    loadChildren: () =>
      import('./pages/activity-detail/activity-detail.module').then(
        (m) => m.ActivityDetailPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'timeline',
    loadChildren: () =>
      import('./pages/timeline/timeline.module').then(
        (m) => m.TimelinePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'parcel-inspections',
    loadChildren: () =>
      import('./pages/parcel-inspections/parcel-inspections.module').then(
        (m) => m.ParcelInspectionsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'parcel-inspections-detail',
    loadChildren: () =>
      import(
        './pages/parcel-inspections-detail/parcel-inspections-detail.module'
      ).then((m) => m.ParcelInspectionsDetailPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./shared/components/tabs/tabs.module').then(
        (m) => m.TabsPageModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'irrigation-service',
    loadChildren: () => import('./pages/irrigation-service/irrigation-service.module').then( m => m.IrrigationServicePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parcel-inspections-add',
    loadChildren: () => import('./pages/parcel-inspections-add/parcel-inspections-add.module').then( m => m.ParcelInspectionsAddPageModule)
  },
  {
    path: 'parcel-inspection-add-activity',
    loadChildren: () => import('./pages/parcel-inspection-add-activity/parcel-inspection-add-activity.module')
    .then( m => m.ParcelInspectionAddActivityPageModule)
  },
  {
    path: 'parcel-inspection-add-disease',
    loadChildren: () => import('./pages/parcel-inspection-add-disease/parcel-inspection-add-disease.module')
    .then( m => m.ParcelInspectionAddDiseasePageModule)
  },
  {
    path: 'disease-detail',
    loadChildren: () => import('./pages/disease-detail/disease-detail.module').then( m => m.DiseaseDetailPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}



