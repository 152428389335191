import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from './core/guards/basic-auth.interceptor';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';
import {BarChart, GaugeChart, GraphChart, HeatmapChart, LineChart} from 'echarts/charts';
import {CalendarComponent, LegendComponent, TitleComponent, TooltipComponent, VisualMapComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import 'echarts/theme/dark.js';

echarts.use(
  [BarChart, LineChart, GaugeChart, GraphChart, HeatmapChart, TitleComponent,
    TooltipComponent, LegendComponent, CalendarComponent, VisualMapComponent, CanvasRenderer]
);

@NgModule({
    declarations: [AppComponent],
  entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgHttpLoaderModule.forRoot(),
        NgxEchartsModule.forRoot({ echarts }),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
