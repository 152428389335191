// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlApi:
    'https://proxy-cors.soltel-idi.es/http://seacon.seacon.hu:8613/API/FarmerPortal',

  //temp
  userInfo: 'user_info',
  urlIrrigationService: 'https://soltel-idi.es/dsv/irrigation/',
  urlMaturityData: 'https://proxy-cors.soltel-idi.es/https://soltel-idi.es/smartom/maduracion',
  irrigationServiceToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJDZW50cmFsU3lzdGVtIn0.xnZHnXrestjMuZvA3DI-LNNee-Gw3a7AQOUQUHqjsD8',

  urls: {
    getSensormeasuredvalues: '/GetSensormeasuredvalues',

    //Parcels Inspections
    createParcelInspection: '/CreateParcelInspection',
    getParcelsinspections: '/GetParcelsinspections',
    getParcelsInspectionsById: '/GetParcelsinspectionsbyID',
    addParcelActivityToParcelInspection: '/AddParcelActivityToParcelInspection',
    addParcelInspectionDiseaseToParcelInspection: '/AddParcelInspectionDiseaseToParcelInspection',
    addPictureToParcelInspection: '/AddPictureToParcelInspection',
    getUnits: '/GetUnits',
    getMaterials: '/GetMaterials',
    getActivities: '/GetActivities',
    getAlerts: '/GetAlerts'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
