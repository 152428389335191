import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(request.url.includes('FarmerPortal')){
      request = request.clone({
        setHeaders: {
          authorization: `Basic ${btoa('FarmerPortalUser:cxDe2GuJeFeLT56e')}`,
        },
      });
    }

    return next.handle(request);
  }
}
